<template>
	<div>
		<v-dialog :value="value" max-width="800px" @input="$emit('input')">
			<v-card>
				<v-card-title>
					<span class="headline">Modifier la tâche</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>

							<v-col cols="12" md="3" sm="12" xs="12">
								<v-select v-model="modifiedTask.statusLabel" :items="this.$listUtils.elementStatus"
									label="Statut" required></v-select>
							</v-col>

							<v-col cols="12" md="6" sm="8" xs="12">
								<v-text-field label="Tâche" v-model="modifiedTask.task"></v-text-field>
							</v-col>

							<v-col cols="12" md="3" sm="4" xs="12">
								<v-select v-model="modifiedTask.category" :items="this.$listUtils.itemCategory"
									item-text="label" label="Catégorie" required></v-select>
							</v-col>

							<v-row class="px-3">
								<v-col cols="12" sm="8">
									<v-autocomplete clearable multiple v-model="modifiedTask.participants" :items="people" chips deletable-chips color="blue-grey lighten-2" label="Client" item-text="[name, id]" item-value="id">
										<template v-slot:selection="data">
											<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
												<v-avatar left>
													<span v-if="!data.item.avatar && data.item.firstName" class="font-weight-regular">{{ data.item.firstName[0] }}{{ data.item.lastName[0] }}</span>
													<span v-else-if="!data.item.avatar && data.item.name" class="font-weight-regular">{{ data.item.name }}</span>

													<v-img v-else :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
												</v-avatar>
												{{ data.item.name }}
											</v-chip>
										</template>

										<template v-slot:item="data">
											<template v-if="data.header">
												<v-list-item-content v-text="data.item"></v-list-item-content>
											</template>
											<template v-else>
												<v-list-item-avatar>
													<span v-if="!data.item.avatar && data.item.firstName" class="font-weight-regular">{{ data.item.firstName[0] }}{{ data.item.lastName[0] }}</span>
													<span v-else-if="!data.item.avatar && data.item.name" class="font-weight-regular">{{ data.item.name }}</span>

													<v-img v-else contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
												</v-list-item-avatar>
												<v-list-item-content>
													<v-list-item-title>{{ data.item.name }}</v-list-item-title>
													<v-list-item-subtitle v-html="'Numéro ' + data.item.denom + ' : ' + data.item.content">
													</v-list-item-subtitle>
												</v-list-item-content>
											</template>
										</template>
									</v-autocomplete>
								</v-col>
								<v-col cols="12" sm="4">
									<v-autocomplete clearable v-model="modifiedTask.project" :items="projects" chips color="blue-grey lighten-2" label="Projet" item-text="[name, id]" item-value="id">
										<template v-slot:selection="data">
											<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
												<v-avatar left>
													<span v-if="!data.item.avatar && data.item.firstName" class="font-weight-regular">{{ data.item.firstName[0] }}{{ data.item.lastName[0] }}</span>
													<span v-else-if="!data.item.avatar && data.item.name" class="font-weight-regular">{{ data.item.name }}</span>

													<v-img v-else :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
												</v-avatar>
												{{ data.item.name }}
											</v-chip>
										</template>

										<template v-slot:item="data">
											<template v-if="data.header">
												<v-list-item-content v-text="data.item"></v-list-item-content>
											</template>
											<template v-else>
												<v-list-item-avatar>
													<span v-if="!data.item.avatar && data.item.firstName" class="font-weight-regular">{{ data.item.firstName[0] }}{{ data.item.lastName[0] }}</span>
													<span v-else-if="!data.item.avatar && data.item.name" class="font-weight-regular">{{ data.item.name }}</span>

													<v-img v-else contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
												</v-list-item-avatar>
												<v-list-item-content>
													<v-list-item-title>{{ data.item.name }}</v-list-item-title>
												</v-list-item-content>
											</template>
										</template>
									</v-autocomplete>
								</v-col>
							</v-row>

							<v-col cols="12" sm="12" xs="12">
								<span class="mb-1">Contenu</span>
								<vue-editor v-model="modifiedTask.content" />
							</v-col>

							<v-col cols="12" md="6" sm="4" xs="12">
								<v-select v-model="modifiedTask.priority" :items="this.$listUtils.priority"
									label="Priorité" required></v-select>
							</v-col>

							<v-col cols="12" md="6" sm="12" xs="12">
								<v-row>
									<v-col cols="12" sm="6">
										<v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="modifiedTask.dueDate" transition="scale-transition" offset-y min-width="auto">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field v-model="formatModifiedDate" label="Date limite" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
												</v-text-field>
											</template>
											<v-date-picker first-day-of-week="1" v-model="modifiedTask.dueDate" scrollable @change="$refs.menu2.save(modifiedTask.dueDate)"></v-date-picker>
										</v-menu>
									</v-col>
									<v-col cols="12" sm="6" v-if="modifiedTask.dueDate">
										<v-menu ref="menu2_1" v-model="menu2_1" :close-on-content-click="false" :nudge-right="40" :return-value.sync="modifiedTask.time" transition="scale-transition" max-width="290" offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field v-model="modifiedTask.time" label="Heure limite" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
											</template>
											<v-time-picker v-if="menu2_1" v-model="modifiedTask.time" @click:minute="$refs.menu2_1.save(modifiedTask.time)" format="24hr"></v-time-picker>
										</v-menu>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-btn color="red darken-1" text @click="modifiedTask.dueDate = ''" v-if="modifiedTask.dueDate">
						Retirer la date limite
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="modifyTask()">
						Modifier
					</v-btn>
					<v-btn color="blue darken-1" text @click.native="$emit('input', false)">
						Fermer
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>


<script>
	import { VueEditor } from "vue2-editor";

	export default {
		props: {
			'modifiedTask': {
				type: Object
			},
			'compId': {
				type: String
			},
			'id': {
				type: String
			},
			'value': {
				type: Boolean
			}
		},
		components: {
			VueEditor
		},
		data() {
			return {
				menu2: false,
				menu2_1: false,

				time: "23:59",

				people: [],
				projects: []
			}
		},
		watch: {
			modifiedTask: {
				deep: true,
				handler() {
					if (typeof this.modifiedTask.status === 'number') this.modifiedTask.statusLabel = this.$listUtils.elementStatus[this.modifiedTask.status];

					if (typeof this.modifiedTask.priority === 'number') this.modifiedTask.priority = this.$listUtils.priority[this.modifiedTask.priority];

					if (this.modifiedTask.dueDate) {
						var tempTime = this.modifiedTask.dueDate.toDate ? this.modifiedTask.dueDate.toDate() : this.modifiedTask.dueDate;

						this.modifiedTask.dueDate = this.$moment(tempTime).format("YYYY-MM-DD");

						this.modifiedTask.time = this.$moment(tempTime).format("HH:mm");
					}
				}
			}
		},
		computed: {
			formatModifiedDate() {
				return this.modifiedTask.dueDate ? this.$moment(this.modifiedTask.dueDate).format('DD/MM/YYYY') : ''
			},
			modifiedDateTime() {
				return new Date(`${this.modifiedTask.dueDate} ${this.modifiedTask.time}`);
			}
		},
		methods: {
			modifyTask() {
				var modifiedTask = {
					...this.modifiedTask,
					'author': this.modifiedTask.author.id,
					'status': this.$listUtils.elementStatus.indexOf(this.modifiedTask.statusLabel),
					'category': this.modifiedTask.category,
					'priority': this.$listUtils.priority.indexOf(this.modifiedTask.priority),
					'lastUpdate': new Date()
				};

				delete modifiedTask.id;

				if(this.modifiedTask.dueDate) modifiedTask.dueDate = this.modifiedDateTime;

				delete modifiedTask.time;
				delete modifiedTask.participantsData;
				delete modifiedTask.projectData;

				this.$db.collection("companies").doc(this.compId).collection("tasks").doc(this.id).set(modifiedTask, {
					merge: true
				}).then(() => {
					this.$emit('init');

					this.$store.dispatch('setSnackbar', {
						text: `La tâche ${this.modifiedTask.task} a été modifiée !`,
					});

					this.$emit('input', false);
				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
					this.people = [{
						header: 'Clients'
					}]

					this.$db.collection("companies").doc(this.userInfo.company).collection("customers").onSnapshot((res) => {
						res.forEach((document) => {
							this.people.push({
								...document.data(),
								'name': document.data().lastName.toUpperCase() +
									" " + document.data().firstName,
								'content': document.data().nClient,
								'id': document.id,
								'avatar': document.data().avatar,
								'denom': "client"
							});
						});

						this.people.push({
							divider: true
						}, {
							header: 'Entreprises'
						})

						this.$db.collection("companies").doc(doc.data().company).collection(
							"companies").onSnapshot((res) => {
							res.forEach((document) => {
								this.people.push({
									...document.data(),
									'name': document.data().name,
									'content': document.data().nCompany,
									'id': document.id,
									'avatar': document.data().avatar,
									'denom': "entreprise"
								});
							});
						});
					});

					this.$db.collection("companies").doc(this.userInfo.company).collection("projects").onSnapshot((res) => {
						res.forEach((document) => {
							this.projects.push({
								'name': document.data().title,
								'id': document.id,
								'avatar': document.data().avatar
							});
						});
					});
				});
			});
		}
	}
</script>