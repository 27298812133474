<template>
	<div>
		<v-row class="mt-5">

			<v-col cols="12">

				<v-card class="ma-0 pa-4 glassmorphism maxWidth mx-auto" elevation="0"
					:color="$vuetify.theme.dark ? 'transparent' : 'white'">

					<v-row>
						<v-col>
							<Title weight="regular" titleSize="h2" class="mx-auto">Les Tâches en cours</Title>
						</v-col>
						<v-col cols="auto">
							<v-btn @click="dialogs.todo = true" outlined>Ajouter une tâche</v-btn>
						</v-col>
					</v-row>

					<v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher" single-line class="maxWidth mx-auto"></v-text-field>

					<v-data-table :headers="$tableHeaders.tasks" :items="tasks" :search="search"
						item-key="id" single-expand show-expand group-by="category"
						class="mx-auto maxWidth glassmorphism">

						<template v-slot:[`item.category`]="{ item }">
							{{ $listUtils.itemCategory[item.category].label }}
						</template>

						<template v-slot:[`item.status`]="{ item }">
							<v-tooltip top :color="$cssGlobal.getStatus($listUtils.elementStatus[item.status])">
								<template v-slot:activator="{ on, attrs }">
									<v-card v-bind="attrs" v-on="on"
										:color="$cssGlobal.getStatus($listUtils.elementStatus[item.status])" height="15"
										width="2">
									</v-card>
								</template>
								<span>{{ $listUtils.elementStatus[item.status] }}</span>
							</v-tooltip>
						</template>

						<template v-slot:[`item.task`]="{ item }">
							<v-skeleton-loader
								type="text"
								v-if="!infoLoaded || !item.task"
							></v-skeleton-loader>

							<span v-else>{{ item.task }}</span>
						</template>

						<template v-slot:[`item.priority`]="{ item }">
							<v-skeleton-loader
								type="text"
								v-if="!infoLoaded || !item.priority"
							></v-skeleton-loader>
							<span v-else>
								{{ isNaN(item.priority) ? item.priority : $listUtils.priority[item.priority] }}
							</span>
						</template>

						<template v-slot:[`item.dueDate`]="{ item }">
							<v-skeleton-loader
								type="text"
								v-if="!infoLoaded"
							></v-skeleton-loader>
							<div v-else-if="item.dueDate">
								{{ $moment(item.dueDate.toDate ? item.dueDate.toDate() : item.dueDate).format("DD/MM/YYYY à HH:mm") }}
								<span
									v-if="$moment(item.dueDate.toDate ? item.dueDate.toDate() : item.dueDate).isBefore($moment(), 'minutes') && item.status != 3">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon dense color="error" v-bind="attrs" v-on="on">
												mdi-alert-circle-outline
											</v-icon>
										</template>
										<span
											v-if="Math.round(Math.abs($moment.duration($moment(item.dueDate.toDate ? item.dueDate.toDate() : item.dueDate).diff($moment())).asHours())) == 0">En
											retard de
											{{ Math.round(Math.abs($moment.duration($moment(item.dueDate.toDate ? item.dueDate.toDate() : item.dueDate).diff($moment())).asMinutes())) }}
											minutes</span>
										<span
											v-else-if="Math.round($moment.duration($moment(item.dueDate.toDate ? item.dueDate.toDate() : item.dueDate).diff($moment())).asDays()) == 0">En
											retard de
											{{ Math.round(Math.abs($moment.duration($moment(item.dueDate.toDate ? item.dueDate.toDate() : item.dueDate).diff($moment())).asHours())) }}
											heures</span>
										<span v-else>En retard de
											{{ Math.round(Math.abs($moment.duration($moment(item.dueDate.toDate ? item.dueDate.toDate() : item.dueDate).diff($moment())).asDays())) }}
											jours.</span>
									</v-tooltip>
								</span>
								<span
									v-else-if="$moment(item.dueDate.toDate ? item.dueDate.toDate() : item.dueDate).isSame($moment(), 'day') && item.status != 3">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon dense color="warning" v-bind="attrs" v-on="on">
												mdi-clock-alert-outline
											</v-icon>
										</template>
										<span
											v-if="Math.round($moment.duration($moment(item.dueDate.toDate ? item.dueDate.toDate() : item.dueDate).diff($moment())).asHours()) > 1">{{ Math.round($moment.duration($moment(item.dueDate.toDate ? item.dueDate.toDate() : item.dueDate).diff($moment())).asHours()) }}
											heures restantes pour effectuer cette tâche.</span>
										<span
											v-else-if="Math.floor($moment.duration($moment(item.dueDate.toDate ? item.dueDate.toDate() : item.dueDate).diff($moment())).asHours()) == 1">1
											heure restante pour effectuer cette tâche.</span>
										<span
											v-else>{{ Math.round($moment.duration($moment(item.dueDate.toDate ? item.dueDate.toDate() : item.dueDate).diff($moment())).asMinutes()) }}
											minutes restantes pour effectuer cette tâche.</span>
									</v-tooltip>
								</span>
							</div>
						</template>

						<template v-slot:[`item.participants`]="{ item }">
							<v-menu bottom min-width="200px" rounded offset-y :close-on-content-click="false"
								v-for="participant in item.participantsData" :key="participant.id">
								<template v-slot:activator="{ on }">
									<v-btn icon v-on="on">
										<v-avatar size="30"
											:style="'background-color: #' + $functions.convertToHex(participant.email) + ';'">
											<span v-if="!participant.avatar && participant.firstName"
												class="font-weight-regular white--text">
												{{ participant.firstName[0] }}{{ participant.lastName[0] }}
											</span>
											<span v-else-if="!participant.avatar && participant.name"
												class="font-weight-regular white--text">
												{{ participant.name[0] }}
											</span>
											<v-img v-else :src="$functions.getImgLink(participant.avatar, 50)"></v-img>
										</v-avatar>
									</v-btn>
								</template>
								<v-card elevation="16">
									<v-list-item-content class="justify-center">
										<div class="mx-auto text-center">
											<v-avatar class="mb-2" size="64"
												:style="'background-color: #' + $functions.convertToHex(participant.email) + ';'">
												<span v-if="!participant.avatar && participant.firstName"
													class="font-weight-regular display-1 white--text">
													{{ participant.firstName[0] }}{{ participant.lastName[0] }}
												</span>
												<span v-else-if="!participant.avatar && participant.name"
													class="font-weight-regular display-1 white--text">
													{{ participant.name[0] }}
												</span>
												<v-img v-else :src="$functions.getImgLink(participant.avatar, 50)"></v-img>
											</v-avatar>

											<h3 v-if="participant.firstName">{{ participant.firstName }}
												{{ participant.lastName }}</h3>
											<h3 v-else>{{ participant.name }}</h3>

											<p class="caption mt-1" v-if="participant.email">
												{{ participant.email }}
											</p>

											<template v-if="participant.report != false">
												<v-divider class="my-3"></v-divider>

												<v-btn outlined @click="$functions.goTo(`record/${participant.name ? 'company' : 'customer'}/${participant.id}`)">
													Voir la fiche
												</v-btn>
											</template>
										</div>
									</v-list-item-content>
								</v-card>
							</v-menu>

						</template>


						<template v-slot:[`item.project`]="{ item }">
							<v-menu
								bottom
								min-width="200px"
								rounded
								offset-y
								:close-on-content-click="false"
								v-if="item.project && item.projectData && item.projectData.title"
							>
								<template v-slot:activator="{ on }">
									<v-btn icon v-on="on">
										<v-avatar size="30">
											<span v-if="!item.projectData.avatar" class="font-weight-regular">
												{{ item.projectData.title[0] }}.
											</span>
											<v-img v-else :src="$functions.getImgLink(item.projectData.avatar, 50)"></v-img>
										</v-avatar>
									</v-btn>
								</template>
								<v-card elevation="16">
									<v-list-item-content class="justify-center">
										<div class="mx-auto text-center">
											<v-avatar class="mb-2" size="64">
												<span v-if="!item.projectData.avatar" class="font-weight-regular">
													{{ item.projectData.title[0] }}.
												</span>
												<v-img v-else :src="$functions.getImgLink(item.projectData.avatar, 50)"></v-img>
											</v-avatar>

											<h3>{{ item.projectData.title }}</h3>

											<v-divider class="my-3"></v-divider>

											<v-btn outlined @click="$functions.goTo(`record/project/${item.projectData.id}`)">
												Voir la fiche
											</v-btn>
										</div>
									</v-list-item-content>
								</v-card>
							</v-menu>

						</template>

						<template v-slot:[`item.manage`]="{ item }">

							<v-btn text icon @click="dialogs.modify = true, modifiedTask = item, modifiedID = item.id">
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on">
											<v-icon color="primary">mdi-pencil</v-icon>
										</div>
									</template>
									<span>
										<span>Modifier la tâche</span>
									</span>
								</v-tooltip>
							</v-btn>

							<v-btn text icon v-if="item.status == 1 || item.status == 0">
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on">
											<v-icon :color="$cssGlobal.getStatus($listUtils.elementStatus[3])"
												@click="statusTask(item, 2), item.status = 2">mdi-play</v-icon>
										</div>
									</template>
									<span>
										<span>Démarrer la tâche</span>
									</span>
								</v-tooltip>
							</v-btn>
							<v-btn text icon v-if="item.status == 2">
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on">
											<v-icon :color="$cssGlobal.getStatus($listUtils.elementStatus[3])"
												@click="statusTask(item, 3), item.status = 3">mdi-check-bold</v-icon>
										</div>
									</template>
									<span>
										<span>Terminer la tâche</span>
									</span>
								</v-tooltip>
							</v-btn>
							<v-btn text icon v-if="item.status == 2">
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on">
											<v-icon :color="$cssGlobal.getStatus($listUtils.elementStatus[2])"
												@click="statusTask(item, 1), item.status = 1">mdi-pause</v-icon>
										</div>
									</template>
									<span>
										<span>Mettre la tâche en attente</span>
									</span>
								</v-tooltip>
							</v-btn>

							<v-btn text icon>
								<v-tooltip top color="error">
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on">
											<v-icon color="error" @click="dialogs.delete = true, deletedTask = item">
												mdi-delete
											</v-icon>
										</div>
									</template>
									<span>
										<span>Supprimer la tâche</span>
									</span>
								</v-tooltip>
							</v-btn>
						</template>

						<template v-slot:expanded-item="{ item }">
							<td :colspan="$tableHeaders.tasks.length">
								<div class="row sp-details">
									<div class="col-12 text-left">
										<v-card-text class="text--primary">
											<v-row>
												<v-col cols="10">
													<h1 class="display-1">{{ item.task }}</h1>
												</v-col>
												<v-col>
													<span class="caption">
														<p>
															<v-icon small>mdi-clock</v-icon> Publication le
															{{ $functions.getDateFormat(item.creationDate) }}
														</p>
														<p>
															<v-icon small>mdi-account</v-icon> Ajouté par {{ item.author.firstName }} {{ item.author.lastName }}
														</p>
													</span>
												</v-col>
											</v-row>

											<p v-if="item.content" v-html="item.content"></p>
											<p v-else class='font-italic'>Aucune description</p>

											<Remarks
												type="tasks"
												:entity="item"
												:user="userInfo"
											/>
										</v-card-text>
									</div>
								</div>
							</td>
						</template>

					</v-data-table>

				</v-card>
			</v-col>
		</v-row>

		<v-dialog v-model="dialogs.delete" max-width="500px">
			<v-card>
				<v-card-title class="headline">
					Voulez vous supprimer cette tâche ?
				</v-card-title>
				<v-card-text>La tâche {{ deletedTask.task }} sera supprimée.</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error darken-1" text @click="deleteTask()">
						Oui, supprimer
					</v-btn>
					<v-btn color="primary darken-1" text @click="dialogs.delete = false">
						Annuler
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<TaskAddDialog v-if="userInfo.company" :userInfo="userInfo" v-model="dialogs.todo" refreshTasks @init="init()"></TaskAddDialog>

		<TaskModifyDialog
			:modifiedTask="modifiedTask"
			:id="modifiedID"
			:compId="userInfo.company"
			v-model="dialogs.modify"
			@init="init()"
		></TaskModifyDialog>

	</div>
</template>

<style scoped>
	::-webkit-scrollbar {
		height: 0px;
	}
</style>

<script>
	import Title from '@/components/graphics/title';
	import TaskModifyDialog from '@/components/modules/@tasks/TaskModifyDialog';
	import TaskAddDialog from '@/components/modules/@tasks/TaskAddDialog';
	import Remarks from '@/components/modules/@widgets/Remarks';

	export default {
		props: {
			List: {
				type: Boolean,
				default: true,
			},
		},
		components: {
			Title,
			TaskModifyDialog,
			TaskAddDialog,
			Remarks
		},
		name: 'TasksList',
		data() {
			return {
				userInfo: this.$models.user,

				tasks: [],
				maxTasks: 0,

				search: '',

				modifiedTask: {
					...this.$models.task,
					time: ''
				},
				modifiedID: '',
				deletedTask: this.$models.task,

				dialogs: {
					delete: false,
					modify: false,
					todo: false
				},

				people: [],
				projects: []
			}
		},
		computed: {
			infoLoaded: function () {
				if(this.tasks.length == this.maxTasks) return true;
				else return false;
			}
		},
		methods: {
			statusTask(task, status) {
				this.$db.collection("companies").doc(this.userInfo.company).collection("tasks").doc(task.id).set({
					'lastUpdate': new Date(),
					'status': status
				}, {
					merge: true
				}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `La tâche ${task.task} a été modifiée !`,
					});
				});
			},
			deleteTask() {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("tasks").doc(this.deletedTask.id), `La tâche ${this.deletedTask.task} a été supprimé !`);

				this.tasks.splice(this.$functions.findWithAttr(this.tasks, "id", this.deletedTask.id), 1);
				this.maxTasks--;

				this.dialogs.delete = false;
			},
			init() {
				const ref = this.$db.collection("companies").doc(this.userInfo.company).collection("tasks");
				this.$functions.getTasks(this.userInfo.company, ref, [0, 1, 2, 3], -1).then((value) => {
					this.tasks = [];
					this.maxTasks = value.length;

					value.forEach(async (v) => {
						v.author = await this.$katana.getUser(v.author);
						this.tasks.push(v);
					});
				});
			},
			async initPeople() {
				this.people = [];
				this.projects = [];

				await this.$functions.queryDatabase(`companies/${this.userInfo.company}/customers`).then((res) => {
					this.people.push({
						header: 'Clients'
					});

					res.forEach((document) => {
						this.people.push({
							...document.data(),
							'name': document.data().lastName.toUpperCase() + " " + document.data().firstName,
							'content': document.data().nClient,
							'id': document.id,
							'avatar': document.data().avatar,
							'denom': "client"
						});
					})
				});

				await this.$functions.queryDatabase(`companies/${this.userInfo.company}/companies`).then((res) => {
					this.people.push({
						header: 'Entreprises'
					});

					res.forEach((document) => {
						this.people.push({
							...document.data(),
							'name': document.data().name,
							'content': document.data().nCompany,
							'id': document.id,
							'avatar': document.data().avatar,
							'denom': "entreprise"
						});
					})
				});

				this.$db.collection("companies").doc(this.userInfo.company).collection("projects").onSnapshot((res) => {
					res.forEach((document) => {
						this.projects.push({
							'name': document.data().title,
							'id': document.id,
							'avatar': document.data().avatar
						});
					});
				});

				await this.$functions.queryDatabase(`companies/${this.userInfo.company}/users`).then((res) => {
					this.people.push({
						header: 'Utilisateurs'
					});

					res.forEach((user) => {
						this.$functions.queryDatabase(`users/${user.id}`).then((res2) => {

							res2.forEach((userData) => {
								this.people.push({
									'name': userData.data().lastName.toUpperCase() + " " + userData.data().firstName,
									'id': user.id,
									'avatar': userData.data().avatar,
									'denom': "utilisateur"
								});
							});

						});
					});
				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				if(this.List) this.init();
				this.initPeople();
			});
		}
	}

</script>
